(function() {
    'use strict';

    angular.module('ionicBugReport', [
        'ionic',
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicBugReport').service('BugReportService', BugReportService);

    BugReportService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'RequestManagementService'
    ];

    function BugReportService(
        DataManagementService,
        DefaultVariableService,
        RequestManagementService
    ) {
        var BugReportService = this;

        BugReportService.createBugReport = createBugReport;
        function createBugReport(newBugReport) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'bug_reports');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newBugReport);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        return DefaultVariableService.getArray(
                            data.bug_reports
                        );
                    }

                    return data;
                },
                function() {
                    return false;
                }
            );
        }

        BugReportService.reset = reset;
        function reset() {

        }

        BugReportService.reset();

        return BugReportService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicBugReport').controller('IonicBugReportController', IonicBugReportController);

    IonicBugReportController.$inject = [
        'AlertService',
        'BugReportService',
        'DefaultVariableService',
        'LoginService'
    ];

    function IonicBugReportController(
        AlertService,
        BugReportService,
        DefaultVariableService,
        LoginService
    ) {
        var IonicBugReportController = this;

        IonicBugReportController.createBugReport = createBugReport;
        function createBugReport(newBugReport) {
            newBugReport = DefaultVariableService.get(
                newBugReport,
                IonicBugReportController.newBugReport
            );

            IonicBugReportController.isCreatingBugReport = true;

            return BugReportService.createBugReport(newBugReport).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('THE BUG REPORT HAS BEEN SUBMITTED.');

                        IonicBugReportController.reset();
                    }

                    return response;
                }
            ).finally(
                function() {
                    IonicBugReportController.isCreatingBugReport = false;
                }
            );
        }

        IonicBugReportController.reset = reset;
        function reset() {
            IonicBugReportController.newBugReport = {};
        }

        IonicBugReportController.init = init;
        function init() {
            IonicBugReportController.reset();
        }

        LoginService.register(IonicBugReportController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicBugReport').directive('ionicBugReport', ionicBugReport);

    function ionicBugReport() {
        return {
            controller:   'IonicBugReportController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<ion-item class="item"><div class="item item-text-wrap item-divider">Please describe in detail the issue you are having with the app. Include what it is you are trying to accomplish, how you are attempting to accomplish it, the results you are getting, error messages, and any other details that you may think will help us find and fix the problem quickly.</div><label class="item item-input"><textarea data-ng-model="ctrl.newBugReport.message" placeholder="DESCRIBE WHAT WENT WRONG...">\n            {{ ctrl.newBugReport.message }}\n        </textarea></label> <button class="button button-full button-positive accept-button" data-ng-click="ctrl.createBugReport()" data-ng-disabled="ctrl.isCreatingBugReport">{{ ctrl.isCreatingBugReport ? \'REPORTING\' : \'REPORT\' }}</button></ion-item>'
        };
    }
})();